/* src/index.css */
body, html, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: 'Gilroy', sans-serif;

}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-RegularItalic.ttf') format('truetype');
  font-weight: 400; /* Regular */
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-MediumItalic.ttf') format('truetype');
  font-weight: 500; /* Medium */
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 600; /* SemiBold */
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600; /* SemiBold */
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: 700; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-BoldItalic.ttf') format('truetype');
  font-weight: 700; /* Bold */
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-ExtraBold.ttf') format('truetype');
  font-weight: 800; /* ExtraBold */
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800; /* ExtraBold */
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Heavy.ttf') format('truetype');
  font-weight: 900; /* Heavy */
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-HeavyItalic.ttf') format('truetype');
  font-weight: 900; /* Heavy */
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Black.ttf') format('truetype');
  font-weight: 950; /* Black */
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-BlackItalic.ttf') format('truetype');
  font-weight: 950; /* Black */
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Thin.ttf') format('truetype');
  font-weight: 100; /* Thin */
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-ThinItalic.ttf') format('truetype');
  font-weight: 100; /* Thin */
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-UltraLight.ttf') format('truetype');
  font-weight: 200; /* UltraLight */
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-UltraLightItalic.ttf') format('truetype');
  font-weight: 200; /* UltraLight */
  font-style: italic;
}


::selection {
  background-color: #30779d;
  color: #ffffff; 
}

::-moz-selection {
  background-color:  #30779d;
  color: #ffffff; 
}
